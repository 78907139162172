.sign-in {
  justify-items: center;
  align-items: center;
  min-height: var(--app-height);
  background-color: #1a1a1a;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 600px){
    align-items: unset;
    min-height: unset;
    background-color: unset;
  }
  video {
    position: absolute;
    top:0;
    left:0;
    min-height: var(--app-height);
    min-width: 100vw;
    z-index:0;
    opacity: .75;
    @media only screen and (max-width: 600px){
      display: none;
    }
  }
  .ui-card {
    z-index: 20;
    max-width: 670px;
    position: relative;
    h1 {
      font-size: 2em;
    }
    img {
      height: 40px;
    }
    @media only screen and (max-width: 600px){
      padding: 24px!important;
      padding-top:36px!important;
      padding-bottom: 36px!important;
      margin:10px!important;
      
      h1 {
        font-size: 1.5em;
      }
      img {
        height: 32px;
      }
      gap: 30px;
      input, button {
        font-size: 16px;
      }
      .ui-label {
        font-size: 16px!important;
        // text-align: center;
      }
    }
  }
}