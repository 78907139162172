@import "categories/variables.scss";
@import "categories/typography.scss";
@import "categories/form.scss";
@import "categories/animations.scss";
@import "categories/colouring.scss";
@import "categories/layouts.scss";

@import "components/loader.scss";
@import "components/card.scss";
@import "components/stat.scss";
@import "components/modal.scss";
@import "components/top-up.scss";

@import "views/signin.scss";

@import "modals/bill.scss";

@import "layouts/members.scss";

body {
  margin: 0;
  background-color: var(--ui-bg);
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.recharts-wrapper {
  font-family: $font-secondary;
  .recharts-legend-item-text {
    color: var(--ui-text);
  }
  .recharts-default-tooltip {
    background-color:var(--ui-border)!important;
    border:1px solid var(--ui-text-lighter)!important;
    border-radius: 4px;
     * {
    color:var(--ui-text)!important;
    }
  }
  .recharts-cartesian-axis-tick {
    font-size: 12px;
  }
}

.referral-url {
  color: white;
  font-weight: 300;
  background-color: rgba(255,255,255,.12);
  border: 1px solid rgba(255,255,255,.6);
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 24px;
  cursor: pointer;
  transition: .2s;
  a {
    color: white;
    text-decoration: none;
  }
  &:hover {
    background-color: rgba(255,255,255,.22);
  }
  @media only screen and (max-width: 800px){
    font-size: 18px;
  }
}