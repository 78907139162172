
.bg {
  @each $name, $colour in $colours {
    &-#{$name} {
      background-color: #{$colour}!important;
    }
  }
}

.colour {
  @each $name, $colour in $colours {
    &-#{$name} {
      color: #{$colour}!important;
    }
  }
}
