.bill-viewer {
    font-family: $font-primary;
    color: map-get($colours, grey);
    .bill-controls {
        position: absolute;
        bottom: 20px;
        width:calc(100% - 40px);
        padding: 10px 15px;
        left: 20px;
        background-color: white;
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        gap: 20px;
        border: 1px solid lightgrey;
        font-size: 14px;
        border-radius: 8px;
        .page-controls {
            justify-self: flex-end;
            i {
                transition: .25s;
                padding: 5px 7px 3px 7px;
                border-radius: 4px;
                cursor: pointer;
                &:first-child {
                    margin-right: 5px;
                }
                &:last-child {
                    margin-left: 5px;
                }
                &:hover {
                    background-color: #eeeeee;
                }
            }
        }
    }
    .annotationLayer {
        display: none!important;
    }
    @media only screen and (max-width: 600px){
        .page-controls {
            display: none;
        }
        > div:first-child {
            display: none;
        }
        .bill-controls {
            position: relative;
            border: 0;
            margin:0;
            bottom:0;
            left:0;
            width: 100%;
            gap: 0;
        }
    }
}