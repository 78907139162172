.members-layout {
  min-height: var(--app-height);
  .sa-navigator {
    display: grid;
    grid-template-columns: minmax(2vw, 1fr) minmax(0, 1350px) minmax(2vw, 1fr);
    overflow: hidden;
    .ui-body {
      font-size: 14px;
    }
    >div {
      grid-column: 2;
      padding: 10px 0px;
      display: grid;
      grid-template-columns: auto auto;
      gap:50px;
      align-items: center;
      @media only screen and (max-width: 800px){
        display: flex;
        flex-direction: column;
      }
      >div:last-child {
        justify-self: flex-end;
        input, button {
          height: 50px;
          padding: 12px;
          font-size: 16px;
        }
      }
    }
  }
  .property-selector {
    display: grid;
    grid-template-columns: minmax(2vw, 1fr) minmax(0, 1350px) minmax(2vw, 1fr);
    overflow: hidden;
    >div {
      grid-column: 2;
      padding: 10px 0px;
      display: grid;
      grid-template-columns: auto auto;
      gap:50px;
      align-items: center;
      @media only screen and (max-width: 800px){
        display: flex;
        flex-direction: column;
      }
      a {
        background: var(--ui-container);
        padding: 8px 16px;
        display: flex;
        align-items: center;
        justify-items: center;
        width: fit-content;
        border: 1px solid var(--ui-border);
        border-radius: 8px;
        transition: .25s;
        text-decoration: none;
        @media only screen and (max-width: 800px){
          display: none;
        }
        &:hover {
          opacity: .75;
        }
        i {
          font-size: 12px;
        }
      }

      .ui-select {
        max-width: 400px;
        background-color: var(--ui-container);
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 14px;
        justify-self: flex-end;
        font-size: 16px;
      }
    }
  }

  .page-layout {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1350px) minmax(0, 1fr);

    >div {
      grid-column: 2;
      background-color: var(--ui-container);
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      position: relative;
      .header {
        display: grid;
        grid-template-columns: auto auto;
        padding: 50px;
        position: absolute;
        width: 100%;
        @media only screen and (max-width: 1000px){
          padding: 30px 20px;
        }
        > a:first-child {
          display: grid;
          align-items: center;
      
        }
        img {
          height: 32px;
        }
        .header-mobile-trigger {
          display: none;
          color: white;
          font-size: 32px;
          justify-self: flex-end;
          align-self: center;
          @media only screen and (max-width: 1000px){
            display: block;
          }
        }
        .header-navigation {
          justify-self: flex-end;
          align-self: center;
          display: flex;
          @media only screen and (max-width: 1000px){
            display: none;
            position: absolute;
            flex-direction: column;
            width: 92%;
            margin-left: 4%;
            margin-right: 4%;
            left:0;
            background-color: var(--ui-container);
            top:90px;
            z-index: 2;
            border-radius: 12px;
            overflow: auto;
            box-shadow: 0 6px 16px 0 rgba(0,0,0,.15), 0 3px 6px 0 rgba(0,0,0,.1);
          }
          a {
            text-decoration: none;
            font-family: $font-primary;
            font-weight: 400;
            padding: 10px 12px;
            transition: .25s;
            border-radius: 8px;
            @media only screen and (max-width: 1000px){
              color: var(--ui-text)!important;
              border-radius: 0;
              padding: 16px 12px;
              border-bottom: 1px solid var(--ui-border);
              width:100%;
              text-align: center;
            }
            &:not(:last-child) {
              margin-right: 6px;
            }

            &:last-child {
              border-bottom: 0;
            }

            &.active {
              background-color: rgba(255, 255, 255, 0.2);
              @media only screen and (max-width: 1000px){
                background-color: var(--ui-border);
              }
            }

            &:not(.active):hover {
              background-color: rgba(255, 255, 255, 0.1);
            }
          }
        }
      }

      .footer-container {
        .footer {
          background-color: #212125;

          .foot-navigation {
            padding: 80px;
            display: grid;
            gap: 15%;
            grid-template-columns: max-content auto;
            @media only screen and (max-width: 1000px){
              display: flex;
              flex-direction: column;
              text-align: center;
            }
            button, a {
              @media only screen and (max-width: 600px){
                display: block;
                width: 100%;
                margin-bottom: 10px;
              }
            }
            .foot-links {
              ul {
                color: white;
                font-family: $font-primary;
                list-style: none;
                padding: 0;
                margin: 0;
                @media only screen and (max-width: 1000px){
                  margin-top: 50px;
                }
                li:not(:first-child) {
                  margin-top: 14px;
                }

                b {
                  font-weight: 500;
                }

                a {
                  text-decoration: none;
                  color: white;
                  font-size: 14px;
                  opacity: .5;
                  transition: .25s;

                  &:hover {
                    opacity: 1;
                  }
                }
              }
            }
          }

          .foot-note {
            padding: 50px 80px;
            display: grid;
            grid-template-columns: auto auto;
            border-top: 0.5px solid #3a3a3a;
            @media only screen and (max-width: 1000px){
              display: flex;
              flex-direction: column;
              text-align: center;
            }
            img {
              height: 36px;
            }

            p {
              align-self: flex-end;
              justify-self: flex-end;
              font-weight: 400;
              font-family: $font-primary;
              @media only screen and (max-width: 1000px){
                margin-top: 30px;
                align-self: unset;
              }
            }
          }
        }
      }
    }
  }
}

.banner {
  background-color: map-get($colours, green);
  background-image: url("../../assets/banner.jpg");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 220px 40px 170px 40px;
  @media only screen and (max-width: 800px){
    padding: 100px 40px 60px 40px;
  }
  &-compact {
    background-color: map-get($colours, green);
    background-image: url("../../assets/banner.jpg");
    background-size: cover;
    background-position: center;
    text-align: center;
    padding: 200px 40px 100px 40px;
    @media only screen and (max-width: 800px){
      padding: 100px 40px 60px 40px;
      .banner-buttons {
        display: grid;
        button {
          padding: 14px 16px;
          font-size: 14px;
          margin-left:0!important;
          &:last-child {
            margin-top: 10px!important;
          }
        }
      }
    }
  }
}

.page-layout {
  @media only screen and (max-width: 800px){
    .padding-50 {
      padding: 50px 5vw !important;
    }
  }
}