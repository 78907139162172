.ui {
  &-input, &-select, &-textarea {
    -webkit-appearance: none;
    color: var(--ui-text);
    font-family: $font-secondary;
    border: 1px solid var(--ui-border);
    font-size: 1em;
    padding: 18px;
    outline: 0;
    background: var(--ui-bg);
    resize: none;
    transition: .2s;
    border-radius: 8px;
    width:100%;
    &:hover:not([disabled]), &:focus {
      background-color: var(--ui-container);
    }
    &.warning {
      border: 1px solid map-get($colours,orange);
    }
  }
  &-input-group {
    display: grid;
    grid-template-columns: auto 150px;
    button {
      border-radius: 0 8px 8px 0;
      height: 65px;
      font-size: 18px;
    }
    input {
      border-radius: 8px 0 0 8px;
      font-size: 18px;
    }
    @media only screen and (max-width: 600px){
      display: flex;
      flex-direction: column;
      button, input {
        border-radius: 8px;
      }
      button {
        margin-top: 10px;
      }
    }
  }
  &-icon-input {
    display: grid;
    grid-template-columns: 50px auto;
    input {
      border-radius: 0 8px 8px 0;
    }
    :not(input) {
      border-radius: 8px 0 0 8px;
      font-size: 16px;
      display: flex;
    align-items: center;
    justify-content: center;
      background: var(--ui-bg);
      color: var(--ui-text);
      border: 1px solid var(--ui-border);
      border-right:0;
    }
  }
  &-select {
    background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" data-prefix="far" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="" d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"></path></svg>');
    background-size: 12px;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: calc(100% - 14px);
    background-clip: padding-box;
    padding-right:40px;
    &[disabled]{
      opacity: .5;
    }
  }
  &-btn {
    text-decoration: none;
    -webkit-appearance: none;
    background-color: var(--ui-container);
    border: 0;
    font-family: $font-primary;
    color: var(--ui-text);
    font-size: 16px;
    padding: 18px 24px;
    border-radius: 8px;
    font-weight: 300;
    outline:0;
    cursor: pointer;
    transition: .2s;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.08);
    &:hover {
      box-shadow: 0px 4px 10px rgba(0,0,0,0.115);
    }
    &:active {
      box-shadow: 0px 0px 4px rgba(0,0,0,0.1);
    }
    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
    &-primary {
      @extend .ui-btn;
      background-color: map-get($colours,green);
      color:white;
    }
    &-secondary {
      @extend .ui-btn;
      background-color: map-get($colours,blue);
      color:white;
    }
    &-basic {
      @extend .ui-btn;
      background-color: var(--ui-bg);
      color:var(--ui-text);
      border: 1px solid var(--ui-border);
    }
    &-flat {
      box-shadow: unset;
    }
    &-compact {
      @extend .ui-btn;
      padding: 12px 12px;
      font-size: 14px;
      border-radius: 8px;
    }
  }
}

[data-theme="dark"] {
  .ui-select {
    background-image: url('data:image/svg+xml;utf8,<svg fill="white" aria-hidden="true" data-prefix="far" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"></path></svg>');
  }
}

.ui-input:-webkit-autofill,
.ui-input:-webkit-autofill:hover, 
.ui-input:-webkit-autofill:focus, 
.ui-input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px var(--ui-bg) inset !important;
}
.ui-input:-webkit-autofill {
  -webkit-text-fill-color: var(--ui-text) !important;
}
.ui-input::placeholder {
  color: var(--ui-text-lighter);
}

.ui {
  &-table {
    &-header {
      opacity: .5;
    }
    max-height: 500px;
    max-width: 90vw;
    overflow: scroll;
    border: 1px solid var(--ui-border);
    table {
    font-family: $font-primary;
    color: var(--ui-text);
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    @media only screen and (max-width: 600px){
      font-size: 12px;
    }
    tbody {
    td {
      border-bottom: 1px solid var(--ui-border);
      min-width: 120px;
      padding: 14px;
      letter-spacing: -.03em;
      @media only screen and (max-width: 1000px){
        padding: 12px;
      }
    }
    tr {
      transition: .25s;
      &:hover {
        background-color: var(--ui-bg);
      }
      &:last-child td {
        border-bottom: 0;
      }
    }
  }
  thead {
    td {
      opacity: .5;
      border-bottom: 1px solid var(--ui-border);
      padding: 14px;
      letter-spacing: -.03em;
      @media only screen and (max-width: 1000px){
        padding: 12px;
      }
    }
  }
}
  }
}