.ui-stat {
  text-align: center;
  padding: 30px;
  @media only screen and (max-width: 800px){
    padding-top:15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  span {
    font-size: 2.25em;
  }
  transition: .25s;
  &-clickable {
    cursor: pointer;
    border-radius: 12px;
    &:hover {
      background-color: var(--ui-bg);
    }
  }
}

.ui-row-stat {
  align-items: center;
  padding: 16px 20px;
  span {
    text-align: right;
  }
  p {
    font-weight: 400;
  }
  border-bottom: 1px solid var(--ui-border);
}