.ui {
  &-card {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    background-color: var(--ui-container);
    border-radius: 12px;

    &-transparent {
      background-color: var(--ui-container-transparent);
    }

    &-message {
      text-align: center;
      align-content: center;
      align-items: unset;
      p {
        font-family: $font-primary;
        color: var(--ui-text-lighter);
        font-weight: 300;
        font-size: 16px;
      }
      i {
        color: var(--ui-text);
        font-size: 32px;
        margin-bottom:16px;
      }
    }
  }
  &-card-button {
    border: 1px solid var(--ui-border-lighter);
    transition: .25s;
    background-color: var(--ui-bg);
    cursor: pointer;
    &:hover:not(.ui-card-button-disabled) {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }
    &:active:not(.ui-card-button-disabled) {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    }
    &-disabled {
      cursor: not-allowed;
    }
  }
}